import Twitter from "@material-ui/icons/Twitter";
import Telegram from "@material-ui/icons/Telegram";
import Discord from "./DiscordIcon";

import './header.css';

const logo = process.env.REACT_APP_LOGO_URL || '/logo192.png';
const name = process.env.REACT_APP_SITE_NAME || 'Your name';
const twitterUrl = process.env.REACT_APP_TWITTER || null;
const discordUrl = process.env.REACT_APP_DISCORD || null;
const telegramUrl = process.env.REACT_APP_TELEGRAM || null;

const twitter = twitterUrl ? <a href={twitterUrl} title="twitter" style={{ color: "#1DA1F2"}} target="_blank" rel="noopener noreferrer"><Twitter /></a> : <></>;
const discord = discordUrl ? <a href={discordUrl} title="discord" target="_blank" rel="noopener noreferrer"><Discord color="#5865F2" /></a> : <></>;
const telegram = telegramUrl ? <a href={telegramUrl} title="telegram" style={{ color: "#0088cc"}} target="_blank" rel="noopener noreferrer"><Telegram /></a> : <></>;

const url = process.env.REACT_APP_SITE_URL ? <a href={process.env.REACT_APP_SITE_URL} target="_blank" rel="noopener noreferrer">{name}</a>
  : <a href="#">{name}</a>
;

const Header = () => {
  return (
    <header className="q-container">
      <div>
        <img src={logo} alt="logo" />
        {url}
      </div>
      <div>
        {twitter}
        {discord}
        {telegram}
      </div>
    </header>
  );
};

export default Header;