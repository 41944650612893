import { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";

import './home.css';

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      const anchorWallet = wallet ? wallet : anchor.web3.Keypair.generate();

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        anchorWallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  const collectionImage = process.env.REACT_APP_COLLECTION_IMAGE_URL ? <img src={process.env.REACT_APP_COLLECTION_IMAGE_URL} width="100%" height="auto" alt="" /> : 
    <>
      <img src="/work.png" width="100%" height="auto" alt="" />
      <Typography component="span" variant="inherit" color="primary">COLLECTION IMAGE</Typography>
    </>
  ;
  
  const collectionName = process.env.REACT_APP_COLLECTION_NAME ? <p>{process.env.REACT_APP_COLLECTION_NAME}</p> : <>YOUR COLLECTION NAME</>;

  const paymentToken = process.env.REACT_APP_PAYMENT_TOKEN ? process.env.REACT_APP_PAYMENT_TOKEN : 'SOL';
  const price = process.env.REACT_APP_PAYMENT_PRICE ? process.env.REACT_APP_PAYMENT_PRICE : 0;

  const url = process.env.REACT_APP_SITE_URL ? <a href={process.env.REACT_APP_SITE_URL} target="_blank" rel="noopener noreferrer">{process.env.REACT_APP_SITE_URL}</a>
    : <></>
  ;

  return (
    <main className="q-container">

      <div>{collectionImage}</div>
      
      <p>{collectionName}</p>
      {url}

      <div id="q-mint-container">
        <p style={{ visibility: !isActive ? 'hidden' : 'visible' }}>Price : {price} {paymentToken}</p>

        {!isActive ?
          <Countdown
            date={startDate}
            onMount={({ completed }) => completed && setIsActive(true)}
            onComplete={() => setIsActive(true)}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              let span = <></>;
              if (completed)
                span = (
                  <Box component="div" sx={{bgcolor: 'primary.main'}} id="q-countdown" style={{cursor: 'pointer'}} onClick={() => window.location.reload()}>
                    <span>Please refresh</span>
                  </Box>
                );
              else
                span = (
                <Box component="div" sx={{bgcolor: 'primary.main'}} id="q-countdown">
                  <span>{days ? days + 'd:' : ''}{days || hours ? hours + 'h:' : ''}{days || hours || minutes ? minutes + 'm:' : ''}{days || hours || minutes || seconds ? seconds + 's' : ''}</span>
                </Box>
                );

              return span;
            }}
          />
          : !wallet ?
            <WalletDialogButton
              className="q-button"
              disableRipple
            >
              Connect Wallet
            </WalletDialogButton>
          : !isSoldOut ?
            <Button
              className="q-button"
              disabled={isMinting || !isActive}
              onClick={onMint}
              variant="contained"
              disableRipple
              color="primary"
            >
              {isMinting ?
                <CircularProgress color="primary" />
                : "MINT"
              }
            </Button>
          : <span>SOLD OUT</span>
        }

        <div>
          {isActive ?
            <p>{itemsRedeemed} / {itemsAvailable}</p>
          : <></>}
          
          {wallet ? (
            <>
              <p>{shortenAddress(wallet.publicKey.toBase58() || "")}</p>
              <p>balance : {(balance || 0).toLocaleString()} {paymentToken}</p>
            </>
          ) : <></>}
          
        </div>
      </div>

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

export default Home;
