import Twitter from "@material-ui/icons/Twitter";
import Discord from "./DiscordIcon";

import './footer.css';

import { WEBSITE_URL, WEBSITE_NAME, WEBSITE_TWITTER, WEBSITE_DISCORD} from './config';

const Footer = () => {
  return (
    <footer className="q-container">
      <p>
        <span>Powered by {WEBSITE_NAME} - 2021</span>
        <span>
          <a href={WEBSITE_TWITTER} target="_blank" rel="noopener noreferrer"><Twitter fontSize="small" /></a>
          <a href={WEBSITE_DISCORD} target="_blank" rel="noopener noreferrer"><Discord fontSize="small" color="#606060" /></a>
        </span>
      </p>

      <p><a href={WEBSITE_URL} title={WEBSITE_NAME + " website"} target="_blank" rel="noopener noreferrer">{WEBSITE_NAME}</a></p>

    </footer>
  );
};

export default Footer;